exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apartment-js": () => import("./../../../src/pages/apartment.js" /* webpackChunkName: "component---src-pages-apartment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-parking-js": () => import("./../../../src/pages/parking.js" /* webpackChunkName: "component---src-pages-parking-js" */),
  "component---src-pages-reservation-js": () => import("./../../../src/pages/reservation.js" /* webpackChunkName: "component---src-pages-reservation-js" */)
}

